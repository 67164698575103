.individual-event-card {
   margin-top: 2%;
}
.card {
   --size: 150px;
   position: relative;
   box-sizing: content-box;
   width: 70vw;
   min-height: var(--size);
   border-left: 5px solid var(--border-color);
   padding: 20px;
   border-radius: 5px;
   cursor: pointer;
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   transition: all 0.2s ease-in-out;
}

.card:hover {
   border: 1px solid var(--border-color);
   border-left-width: 5px;
   border-right-width: 30px;
   /* transform: scale(1.01); */
}

.card.open {
   --border-color: #099268;
}

.card.On-going {
   --border-color: dodgerblue;
}

.card.Ended {
   --border-color: #f03e3e;
}

.card .image {
   width: var(--size);
   height: var(--size);
   border-radius: 5px;
   overflow: hidden;
}

.card .image img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.card .left-container {
   min-height: var(--size);
}

.event_title {
   font-size: 1.5rem;
   font-weight: bold;
}
.event-main-content {
   display: flex;
   align-items: center;
   gap: 40px;
}

.event-main-content .status-btn {
   box-sizing: content-box;
   color: #fff;
   background: var(--border-color);
   cursor: pointer;
   border-radius: 20px;
   padding: 5px;
   padding-right: 10px;
   font-size: 0.8rem;
   width: 70px;
   text-align: center;
   margin-top: 2vh;
}
.event-description {
   padding-top: 3%;
   margin-top: 1vh;
   padding-left: 3%;
}
.card i {
   color: rgba(0, 0, 0, 0.5);
}
.card .right-container {
   min-height: var(--size);
   border-left: 2px solid rgba(0, 0, 0, 0.1);
   padding: 0 0 0 12px;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.right-container {
   margin-top: 4vh;
}

.card .right-container .date,
.card .right-container .prize,
.card .right-container .duration,
.card .right-container .team-size {
   display: flex;
   align-items: center;
   gap: 17px;
}

.tag {
   margin-top: 2%;
   display: flex;
   align-items: flex-start;
   gap: 10px;
}

.tag i {
   transform: translateY(6px);
}

.tag .content {
   display: flex;
   flex-wrap: wrap;
   gap: 7px;
}

.tag .content span {
   box-sizing: content-box;
   color: #404346;
   background: #e7edfd;
   cursor: pointer;
   border-radius: 3px;
   padding: 5px 8px;
   font-size: 0.8rem;
   margin-top: 1vh;
}
