.header {
   height: 80vh;
}
.header-text {
   margin-top: auto;
   margin-bottom: auto;
}

.header-excel {
   color: #2a2a53;
   font-size: 3.5rem;
   font-weight: 600;
}

.header-desc {
   font-size: 1.1rem;
   color: #8585a8;
}

.excel-logo {
   width: auto;
   margin-top: auto;
   margin-bottom: auto;
}

/* TYPING ANIMATION */
