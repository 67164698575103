.hamburger {
   padding: 10%;
   margin: 10px;
   display: inline-block;
   cursor: pointer;
   background-color: transparent;
   border: 0;
   margin-top: 40%;
   margin-left: -25%;
}

.hamburger,
.navigation {
   transition: transform 0.3s 0.1s ease-in-out;
}

.hamburger__box {
   width: 1.7rem;
   display: inline-block;
   position: relative;
}

.hamburger__inner {
   width: 100%;
   height: 4px;
   background-color: #1a1a1a;
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-30%);
   transition: background-color 0.1s 0.2s ease-in-out;
   z-index: 200;
}

.hamburger__inner::before,
.hamburger__inner::after {
   width: 100%;
   height: 4px;
   background-color: #1a1a1a;
   position: absolute;
   content: "";
   left: 0;
   transition: transform 0.2s 0.2s ease-in-out;
   z-index: 200;
}

.hamburger__inner::before {
   top: -10px;
}

.hamburger__inner::after {
   top: 10px;
}

.hamburger--active .hamburger__inner {
   background-color: transparent;
}

.hamburger--active .hamburger__inner:before {
   transform: translateY(10px) rotate(45deg);
   margin-top: -23vh;
}

.hamburger--active .hamburger__inner:after {
   transform: translateY(-10px) rotate(-45deg);
   margin-top: -23vh;
}

.navigation {
   width: 60vw;
   background-color: #fff;
   position: absolute;
   top: 0;
   left: -7vh;
   transform: translateX(-300px);
   z-index: 100;
   box-shadow: 3px 3px 10px rgb(143 129 119 / 20%);
   padding-left: 5%;
   min-height: 100%;
   height: 100%;
   overflow-y: scroll;
}

.navigation--active {
   transform: translateX(0px);
}
.smaller-screen-sidebar {
   margin: 20px;
   width: 50vw;
   border-radius: 5px;
   padding: 10px;
   padding-left: 12%;
   background-color: #fff;
   min-height: 80%;
   height: 90%;
}
.sort-by-title {
   margin-top: 10%;
}
