/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 800px) {
   .header {
      height: 50vh;
   }
   .Sidebar {
      display: none;
   }
   .mobile-sidebar {
      display: block;
      margin-left: 4%;
   }
   .card {
      width: 75vw;
   }
   .left-container,
   .right-container,
   .image {
      padding-top: 3%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      font-size: 0.87rem;
   }

   .right-container {
      margin-top: 2%;
   }

   /* Individual events */
   .status-btn span {
      display: none;
   }
   .time-rem-btn {
      display: none;
   }
   /* footer */
   .footer-row {
      margin-top: 5%;
   }
   .header {
      padding: 13%;
      height: 70vh;
      width: 100vw;
   }

   .excel-logo {
      width: 80% !important;
      height: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 10%;
   }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 800px) and (max-device-width: 1200px) {
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1200px) and (max-width: 3000px) {
   .mobile-sidebar {
      display: none !important;
   }
}
