.search-bar > input {
   height: 50px;
   width: 100%;
   padding: 15px;
   outline: none;
   border: 0;
}

.search-bar {
   border-radius: 5px;
   border: 1px solid rgb(234, 233, 233);
   display: flex;
   background-color: white;
   outline: none;
}

.search-icon {
   height: 30px;
   padding-top: 10px;
   padding-right: 3px;
}

.search-clear {
   height: 30px;
   padding-top: 12px;
   padding-right: 3px;
   padding-bottom: 12px;
}
.search-clear i {
   padding: 5px;
}

.search-clear i:hover {
   background-color: #8585a8;
   border-radius: 4px;
}
