.Sidebar {
   margin: 20px;
   width: 17vw;
   border-radius: 5px;
   padding: 10px;
   padding-left: 2%;
   background-color: #ffffff;
}
.filter {
   margin-top: 20%;
}
select {
   outline: none;
   -webkit-appearance: none;
   font: 14px "Open Sans", sans-serif;
   cursor: pointer;
   color: #3c434a;
   border-color: #a6afb7;
   padding: 8px 16px;
   border-radius: 3px;
   background: #ffffff url("https://i.imgur.com/hRbauFc.png") no-repeat scroll right center;
}
select:hover,
select:active {
   border-color: #85929d;
}
.filter form {
   padding-left: 5%;
}
.filter label {
   padding-left: 2%;
}
